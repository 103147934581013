<template>
  <div class="contain" v-title data-title="结算页">
    <div class="addressnav">
      <div class="address" v-if="addtessStatus" @click="toAddress">
        <div style="width: 10%">
          <img src="@/assets/img/positioning.png" style="width: 20px;height: 20px;vertical-align: middle" />
        </div>
        <div style="flex: 1">
          <div class="bold">
            <span class="fz16">{{addressData.addressContactName}} </span>
            <span class="fz16"> {{addressData.addressContactPhone}}</span>
          </div>
          <div class="fz12" style="margin-top: 6px;">{{addressData.addressPname}}{{addressData.addressCname}}{{addressData.addressAname}}{{addressData.addressDetail}}</div>
        </div>
        <div>
          <van-icon name="arrow" size="20px"/>
        </div>
      </div>
      <div class="address" v-else @click="toAddress">
        <div class="fz14" style="flex: 1">
          <img src="@/assets/img/positioning.png" style="width: 20px;height: 20px;vertical-align: middle" />请选择收货地址
        </div>
        <div>
          <van-icon style="vertical-align: -3px" name="arrow" size="20px"/>
        </div>
      </div>
    </div>
    <div class="order">
      <div class="order1">
        <div v-for="(item, index) in dataList" :key="index">
          <div class="oa">
            <van-icon name="shop-o" size="18px"/>
            <span class="fz13 bold" style="vertical-align: 4px">{{item.shopName}}</span>
          </div>
          <div class="ob" v-for="(item2, index2) in item.clearEventVo" :key="index2">
            <div class="ob-1" v-if="item2.youhuiName">
              <div style="margin-right: 4px">
                <span style="padding: 2px 5px;background-color: #E40404;color: #fff;border-radius: 3px">{{item2.youhuiName}}</span>
              </div>
              <div v-if="item2.eventType === 10">{{item2.ruleName}}</div>
              <template v-else>
                <div>{{item2.eventMessageYm || ''}}</div>
                <div>{{item2.eventMessageWm || ''}}</div>
              </template>
            </div>
            <div class="ob-2" v-for="(item3, index3) in item2.clearProduct" :key="index3">
              <div style="width: 28%">
                <van-image width="77px" height="77px" :src="img_url + item3.proImage" />
              </div>
              <div style="flex: 1">
                <div class="fz14 bold">[{{item3.brandName}}]{{item3.proName}}</div>
                <div class="cob3">
                  <span style="color: #D7A963" v-if="item3.proAlias">[{{item3.proAlias}}]</span>
                  <span>{{item3.specName}}{{item3.unitName}}</span>
                </div>
                <div class="mt10">
                  <span class="bold fz14">¥{{item3.onlinePrice}} </span>
                  <span class="cob3 fz12" style="text-decoration: line-through;margin-left: 4px;">¥{{item3.marketPrice}}</span>
                </div>
              </div>
              <div style="align-self: center">
                x{{item3.productNum}}
              </div>
            </div>
            <div class="ob-2" v-if="item2.clearGiftVo && item2.clearGiftVo.length">
              <div style="width: 15%" v-if="item2.giftStatus">赠品：</div>
              <div class="fz14 bold">
                <div v-for="(item3, index3) in item2.clearGiftVo" :key="index3">
                  <span v-if="item3.proSpecificationUnit">{{item3.proName}} x {{item3.proNumber}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order2">
        <div class="oc">
          <van-field class="ocinput" v-model="note" maxlength="70" placeholder="给商家留言，最多70个字" type="textarea" rows="2" show-word-limit/>
        </div>
        <div class="oe">
          <div class="oe-item">
            <div>商品数量：</div>
            <div>共<span class="bold">{{totalNum}}</span>件商品</div>
          </div>
          <div class="oe-item">
            <div>市场价：</div>
            <div style="text-decoration: line-through;">¥{{marketPrice}}</div>
          </div>
          <div class="oe-item">
            <div>线上价：</div>
            <div>¥{{clearPrice}}</div>
          </div>
          <div class="oe-item">
            <div style="color: red;font-weight: bold">该价格只作为参考，不作为实际成交价格</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bl">
        <span class="fz14">合计：</span>
        <span class="fz18 coe4 bold"><span class="fz12">￥</span>{{clearPrice}}</span>
      </div>
      <div class="br" @click="submit">提交订单</div>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { versions, switchYouHui, accAdd } from '@/common/util'
import { Toast, Dialog } from 'vant'
import {
  selectShoppingCartDefaultAddress,
  selectCatAndClear,
  dptCommonVerify,
  getwxmindata
} from '../../common/api'

export default {
  name: 'confirmOrderDh',
  data () {
    return {
      addtessStatus: true,
      addressData: '',
      dataList: [],
      note: '',
      ordPaymentMethod: '',
      paymethodList: [],
      pcEnum: '',
      // --------------//
      sessionID: '',
      proData: '',
      totalNum: 0,
      eventPrice: 0,
      marketPrice: 0,
      clearPrice: 0,
      rebatePrice: 0,
      deliverPrice: 0,
      price: 0,
      initPrice: 0,
      jiFenPrice: 0,
      ordResource: '',
      img_url: process.env.VUE_APP_IMG_URL,
      infoStatus: true,
      // areaList,
      infoPop: false,
      selectAddress: false,
      addressVal: '',
      reData: '',
      appId: ''
    }
  },
  async mounted () {
    const ver = versions()
    if (ver.wxMiniProgram) {
      this.sessionID = this.$route.query.sessionID
      localStorage.setItem('sessionID', this.sessionID)
      this.appId = this.$route.query.appId
      localStorage.setItem('appId', this.appId)
      this.reData = this.$route.query.data
      const address = this.$route.query.address
      this.ordResource = 'MIN'
      if (address === 'false') {
        this.getAddress()
      } else {
        this.addressData = JSON.parse(address)
      }
      // 小程序需要解析才能获取数据
      await this.getwxmindata()
      await this.getList()
    }
  },
  methods: {
    async getList () {
      const tmpData = this.proData
      tmpData.paymentMethod = this.ordPaymentMethod
      this.$loading.show()
      await selectCatAndClear(tmpData).then(res => {
        this.$loading.hide()
        if (res.result >= 0) {
          this.dataList = res.resultObject.clearShopVoList
          this.jiFenYuJiL = res.resultObject.jfzs
          let num = 0
          // 促销立减eventPrice
          let eventPrice = 0
          // 市场价marketPrice
          let marketPrice = 0
          // 线上价clearPrice
          let clearPrice = 0
          // 商家返利rebatePrice
          let rebatePrice = 0
          // 运费
          let deliverPrice = 0
          // 实付合计
          let price = 0
          this.dataList.forEach(i => {
            eventPrice = accAdd(eventPrice, i.eventPrice)
            marketPrice = accAdd(marketPrice, i.marketPrice)
            clearPrice = accAdd(clearPrice, i.clearPrice)
            rebatePrice = accAdd(rebatePrice, i.rebatePrice)
            deliverPrice = accAdd(deliverPrice, i.deliverPrice)
            price = accAdd(price, i.price)
            i.clearEventVo.forEach(j => {
              const a = switchYouHui(j.eventType)
              j.youhuiName = a
              if (j.clearGiftVo) {
                const giftStatus = j.clearGiftVo.some(h => {
                  return h.proSpecificationUnit
                })
                j.giftStatus = giftStatus
              }
              j.clearProduct.forEach(k => {
                num = accAdd(num, k.productNum)
              })
            })
          })
          this.totalNum = num
          this.eventPrice = eventPrice
          this.marketPrice = marketPrice
          this.clearPrice = clearPrice
          this.rebatePrice = rebatePrice
          this.deliverPrice = deliverPrice
          this.price = price
          this.initPrice = price
        } else {
          Toast(res.message)
        }
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    // 获取地址
    getAddress () {
      selectShoppingCartDefaultAddress().then(res => {
        if (res.resultObject) {
          this.addressData = res.resultObject
        } else {
          this.addtessStatus = false
        }
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    // 小程序需要解析才能获取数据
    async getwxmindata () {
      const tmpData = {
        data: this.reData
      }
      await getwxmindata(tmpData).then(res => {
        this.proData = res.resultObject
      }).catch(err => {
        console.error(err)
        Toast('订单超时请重新下单')
        setTimeout(() => {
          wx.miniProgram.switchTab({
            url: '/pages/shopCart/shopCart',
            fail: err => {
              console.error(err)
              Toast(err)
            }
          })
        }, 2000)
      })
    },
    // 创建订单
    commonOrderCreate () {
      const list = []
      this.dataList.forEach(i => {
        i.clearEventVo.forEach(j => {
          j.clearProduct.forEach(k => {
            k.shopName = i.shopName
            list.push(k)
          })
        })
      })
      const tmpList = list.map(i => ({
        dptDeliveryNoteProAmt: i.onlinePrice * i.productNum,
        dptDeliveryNoteProPrice: i.onlinePrice,
        dptDeliveryNoteProCostPrice: i.onlinePrice,
        dptDeliveryNoteProName: i.proName,
        dptDeliveryNoteProNum: i.productNum * 10000,
        dptDeliveryNoteProSpec: i.specName,
        dptDeliveryNoteProUnit: i.unitName,
        id: i.proId
      }))
      const tmpData = this.dataList.map(i => {
        return {
          customerContactUser: this.addressData.addressContactName,
          customerContactPhone: this.addressData.addressContactPhone,
          customerAddress: this.addressData.addressPname + this.addressData.addressCname + this.addressData.addressAname + this.addressData.addressDetail,
          mid: i.mid,
          shopId: i.shopId,
          shopName: i.shopName,
          deliveryRemarks: this.note,
          noteSource: 'WXMIN',
          proList: tmpList
        }
      })
      const tmpData2 = {
        insertNoteAos: tmpData
      }
      const resData = JSON.stringify(tmpData2)
      const sessionID = this.sessionID
      wx.miniProgram.redirectTo({
        url: `/pages/payDh/payDh?data=${resData}&sessionID=${sessionID}`,
        fail: err => {
          console.error(err)
          Toast(err)
        }
      })
    },
    submit () {
      if(this.addtessStatus){
         // 判断资料完善情况
         dptCommonVerify().then(res => {
        const infoStatus = res.resultObject.label_all
        const ver = versions()
        if (infoStatus) {
          this.commonOrderCreate()
        } else {
          Dialog.confirm({
            title: '提示',
            message: '为了更好的为您服务，请先完善资料'
          }).then(() => {
            if (ver.wxMiniProgram) {
              wx.miniProgram.navigateTo({
                url: '/pages/personCenter/completeInfo/completeInfo?from=H5',
                fail: err => {
                  console.error(err)
                  Toast(err)
                }
              })
            }
          }).catch(() => {
            console.log('用户关闭了弹窗')
          })
        }
      }).catch(err => {
        console.error(err)
      })
      }else {
        Dialog.alert({
        title: '请选择收货地址'
      })
      }
    },
    toAddress () {
      const ver = versions()
      if (ver.wxMiniProgram) {
        wx.miniProgram.navigateTo({
          url: '/pages/personCenter/address/address?select=dh',
          fail: err => {
            console.error(err)
            Toast(err)
          }
        })
      }
    }
  }

}
</script>

<style scoped lang="scss">
.contain {
  background-color: #F3F3F3;
  padding: 10px 10px 200px 10px;
  height: 100%;
}
.topnav {
  ::v-deep .van-nav-bar__left .van-icon{
    color: #000;
  }
  ::v-deep .van-nav-bar__right .van-icon{
    color: #000;
  }
}
.addressnav {
  //margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  .address {
    display: flex;
    align-items: center;
  }
}
.order {
  border-radius: 4px;
  margin-top: 10px;
  background-color: #fff;
}
.order1 {
  .oa {
    padding: 10px 10px 8px 10px;
    border-bottom: 1px solid #f5f5f5;
  }
  .ob {
    .ob-1 {
      padding: 10px;
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      border: 1px solid #f0f0f0;
    }
    .ob-2 {
      padding: 10px;
      display: flex;
      border-bottom: 1px solid #f5f5f5;
    }
  }
}
.order2 {
  .oc {
    border-bottom: 1px solid #f5f5f5;
    .ocinput {
    }
    ::v-deep .ocinput span {
      font-size: 12px;
    }
    ::v-deep .ocinput .van-field__control {
      font-size: 12px;
    }
  }
  .od {
    padding: 10px;
    text-align: right;
    border-bottom: 1px solid #f5f5f5;
  }
  .oe {
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    .oe-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4px;
    }
    .oe-item3 {
      margin-top: 4px;
    }
  }
  .of {
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    .of-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.paymethod {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 4px;
  .pa {
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
  }
  .pb {
    .pb-item {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #f5f5f5;
    }
  }
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 10px;
  .bl {
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
  .br {
    width: 150px;
    text-align: center;
    color: #fff;
    background-color: #E40404;
    font-size: 16px;
    font-weight: bold;
    line-height: 44px;
    border-radius: 30px;
  }
}
.visibility {
  visibility: hidden;
}
.jifeninput {
  border: 1px solid #ccc;
  display: inline-block;
  width: 50px;
  height: 24px;
  line-height: 24px;
  padding: 0 0 0 6px;
  vertical-align: -6px;
}
.jifenpop {
  padding: 20px;
  width: 300px;
  .jifen-line {
    margin-top: 18px;
    .jifen-title {
      font-weight: bold;
      border-left: 8px solid #E40404;
      padding-left: 4px;
    }
    .jifen-text {
      margin-top: 8px;
    }
  }
}
</style>
